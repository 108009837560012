<script setup>
import { ref } from 'vue'
import { mountAllEMListener } from '@/IM/listener'
import { EaseChatSDK, EaseChatClient } from '@/IM/initwebsdk'
import ring from '@/assets/ring.mp3'
import { getLoginUserInfo } from '@/api/login'
/* callkit */
// import EaseCallKit from '@/components/EaseCallKit'
// import InviteCallMembers from '@/components/InviteCallMembers'
/* 【重要】挂载IM相关监听回调。 */
mountAllEMListener()

const syncLogin = async (token) => {
    window.localStorage.setItem(
        'BB_Tk',
        token)


    const res1 = await getLoginUserInfo()
    console.log("message =>", res1.data)
    EaseChatClient.open({
        user: res1.data.imId,
        accessToken: res1.data.imToken,
    })
    window.localStorage.setItem(
        'EASEIM_loginUser',
        JSON.stringify({
            user: res1.data.imId,
            accessToken: res1.data.imToken,
        })
    )
}

window.addEventListener('message', (e) => {
    if (e && e.data) {
        const event = e.data
        if (event.type === 'chatLogin') {
            console.log(event.params)
            const tk = event.params.tk
            syncLogin(tk)
        }
    }
})


/* 重新登陆 */
//读取本地EASEIM_loginUser
const EASEIM_loginUser = window.localStorage.getItem('EASEIM_loginUser')
const loginUserFromStorage = JSON.parse(EASEIM_loginUser) || {}
const handleRelogin = () => {
    console.log('重新登陆')
    EaseChatClient.open({
        user: loginUserFromStorage.user,
        accessToken: loginUserFromStorage.accessToken
    })
}
if (loginUserFromStorage?.user && loginUserFromStorage?.accessToken) {
    handleRelogin()
}
/* EaseCallKit 相关 */
const easeCallKit = ref(null)
const inviteCallComp = ref(null)
//多人会议使用-弹出邀请模态框
const showModal = ({ groupId }) => {
    console.log('可以弹出邀请框', groupId)
    inviteCallComp.value.alertDialog(groupId)
}
//多人会议使用-传递给邀请组件发送邀请消息
const sendMulitInviteMsg = (targetIMId) => {
    console.log('targetIMIdtargetIMIdtargetIMId', targetIMId)
    const callType = 2
    easeCallKit.value.inMultiChanelSendInviteMsg(targetIMId, callType)
}
</script>
<template>
    <router-view v-slot="{ Component }">
        <transition name="slide-fade" mode="out-in" :duration="{ enter: 500, leave: 300 }">
            <component :is="Component" />
        </transition>
    </router-view>
    <!-- 铃声标签 -->
    <audio id="ring" :src="ring" controls hidden></audio>
    <!-- About EaseCallKit -->
    <!-- <EaseCallKit ref="easeCallKit" :EaseIMClient="EaseChatClient" :msgCreateFunc="EaseChatSDK.message"
        @onInviteMembers="showModal" />
    <InviteCallMembers ref="inviteCallComp" @sendMulitInviteMsg="sendMulitInviteMsg" /> -->
</template>

<style type="scss">
@import './styles/reset/reset.css';
@import './styles/iconfont/iconfont.css';

/* .slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0.3;
}

.slide-fade-enter-to,
.slide-fade-leave-from {
  opacity: 1;
} */
</style>
